<template>
  <div class="info">
    <el-col>
      <div class="item">
        <div class="label">会员名称</div>
        <div class="value">{{memberInfo.memberName}}</div>
      </div>
      <div class="item">
        <div class="label">会员手机号</div>
        <div class="value">{{memberInfo.mobile}}</div>
      </div>
      <!-- <div class="item">
        <div class="label">会员编号</div>
        <div class="value">{{memberInfo.cardCode}}</div>
      </div> -->
      <div class="item">
        <div class="label">会员卡号</div>
        <div class="value">{{memberInfo.cardNum}}</div>
      </div>
      <!-- <div class="item">
        <div class="label">剩余可用优惠券（张）</div>
        <div class="value">{{memberInfo.couponNumber}}</div>
      </div> -->
    </el-col>
    <el-col>
      <div class="item">
        <div class="label">性别</div>
        <div class="value">{{memberInfo.sexStr}}</div>
      </div>
      <div class="item">
        <div class="label">会员余额</div>
        <div class="value">{{memberInfo.balance}}</div>
      </div>
      <div class="item">
        <div class="label">出生日期</div>
        <div class="value">{{memberInfo.birthday}}</div>
      </div>
      <div class="item">
        <div class="label">注册会员时间</div>
        <div class="value">{{memberInfo.createdTime}}</div>
      </div>
    </el-col>
  </div>
</template>

<script>
  /**
   * 会员基本信息组件
   */
  export default {
    props: {
      "baseInfo": {
        type: Object,
        require: true,
        default: () => {
          return {}
        }
      }
    },
    watch: {
      baseInfo: function (newVal, oldVal) {
        this.initView(newVal.id)
      }
    },
    data() {
      return {
        memberInfo: {
          memberName: '',
          mobile: '', 
          // cardCode: '',
          cardNum: '',
          couponNumber: 0,
          sexStr: '',
          balance:'',
          birthday: '',
          createdTime: '',
        },
      }
    },
    methods: {
      initView(data) {
        this.post('mall-service/v1/consumerAmount/getDetail', {
          id: data
        }, {}).then(res => {
          this.memberInfo = {
            memberName: res.memberName,
            mobile: res.mobile,
            // cardCode: res.cardCode,
            cardNum: res.cardNum ? res.cardNum : '--',
            couponNumber: res.couponNumber,
            sexStr: res.sex ? ['男', '女'][Number(res.sex) - 1] : '',
            balance: res.balanceAmount ? '￥' + Number(res.balanceAmount).toFixed(2) : '￥0.00',
            birthday: res.birthday,
            createdTime: res.createdTime,
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .info {
    display: flex;
    flex-direction: row;
    padding-left: 100px;
    padding-top: 10px;
    width: 40%;
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;

    .label {
      width: 180px;
      text-align: right;
    }

    .value {
      margin-left: 20px;
      color: #5a5252;
      width: 200px;
    }
  }
</style>