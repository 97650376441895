<template>
  <div class="c">
    <app-header title="会员详情" :isShowBack="true"></app-header>
    <member :baseInfo="baseInfo" />
    <common-tab class="commonTab" :tabs="tabs" @tabChange="onTabChange" :tabIndex="tabIndex" />
    <keep-alive>
      <recharge-record v-if="tabIndex == 0"/> 
      <consume-record v-if="tabIndex == 1" />
      <!-- <coupons-record v-if="tabIndex == 2" /> -->
    </keep-alive>
  </div>
</template>

<script>
  /**
   * 会员详情
   */
  import CommonTab from '../../components/common-tab.vue'
  import member from './components/member.vue'
  import RechargeRecord from './components/rechargeRecord.vue'
  import ConsumeRecord from './components/consumeRecord.vue'
  // import CouponsRecord from './components/couponsRecord.vue'
  export default {
    components: {
      member,
      CommonTab,
      RechargeRecord,
      ConsumeRecord,
      // CouponsRecord
    },
    name: 'MemberDetail',
    RechargeRecord,
    data() {
      return {
        baseInfo: {},
        tabs: [
          {
            label: '充值记录'
          },
          {
            label: '消费记录'
          },
          // {
          //   label: '优惠券'
          // }

        ],
        tabIndex: 0
      }
    },

    mounted() {
      let id = this.$route.query.id
      this.queryDetail(id)
      let tabIndex = this.cache.get('memberDetailTabIndex');
      if(tabIndex) {
        this.tabIndex = Number(tabIndex);
      }else {
        this.tabIndex = 0;
      }
    },
    methods: {
      onTabChange(tabIndex) {
        this.tabIndex = tabIndex;
        this.cache.set('memberDetailTabIndex',tabIndex)
      },
      queryDetail(id) {
       this.baseInfo = {
          id: id
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .c {
    display: flex;
    flex-direction: column;
  }

  .commonTab {
    margin-top: 20px;
  }
</style>