var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info" },
    [
      _c("el-col", [
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("会员名称")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.memberInfo.memberName))
          ])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("会员手机号")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.memberInfo.mobile))
          ])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("会员卡号")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.memberInfo.cardNum))
          ])
        ])
      ]),
      _c("el-col", [
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("性别")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.memberInfo.sexStr))
          ])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("会员余额")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.memberInfo.balance))
          ])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("出生日期")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.memberInfo.birthday))
          ])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("注册会员时间")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.memberInfo.createdTime))
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }