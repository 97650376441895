<template>
  <div class="c">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
  </div>
</template>

<script>
  /**
   * 消费记录组件
   */
  export default {
    data() {
      let _this = this;
      return {
        opt: {
          search: [{
            key: "consumeTime",
            label: "消费时间",
            timeType: "yyyy-MM-dd HH:mm:ss",
            format: "yyyy-MM-dd",
            type: "daterange"
          }],
          columns: [{
              label: "订单编号",
              key: "orderId",
              on: row => {
                _this.$router.push({
                  path: "/main/salesOrder/detail",
                  query: {
                    id: row.orderId,
                  }
                })
              }
            },
            {
              label: "门店-货柜",
              key: "groupCounter"
            },
            {
              label: "商品名称",
              key: "itemName"
            },
            // {
            //   label: "会员卡",
            //   key: "memberCard"
            // },
            {
              label: "会员卡号",
              key: "cardNum"
            },
            {
              label: "消费金额",
              key: "amountStr"
            },
            {
              label: "支付时间",
              key: "createTime"
            },
            {
              label: "支付方式",
              key: "payMethodStr"
            },
            {
              label: "支付状态",
              key: "statusStr"
            }
          ]
        }
      }
    },

    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          userId: this.$route.query.userId,
          createTimeBegin: opt.searchForm.consumeTime ? opt.searchForm.consumeTime[0] : null,
          createTimeEnd: opt.searchForm.consumeTime ? opt.searchForm.consumeTime[1] : null,
          ...opt.searchForm
        }
        this.post('mall-service/memberConsumeRecord/page/query', dto, {
          isUseResponse: true
        }).then(res => {
          res.data.data = res.data.list;
          if (res.data.data && res.data.data.length) {
            res.data.data.forEach(item => {
              if(item.status == 3) {
                item.statusStr = '支付成功';
              }
              if (item.payMethod == 1) {
                item.payMethodStr = "微信支付";
              } 
              // else if (item.payMethod == 5) {
              //   item.payMethodStr = "微信支付（使用优惠券）";
              // }
              else if (item.payMethod == 4) {
                item.payMethodStr = "会员余额支付";
              }
              else if (item.payMethod == 6) {
                item.payMethodStr = "刷卡支付";
              }
              // else if (item.payMethod == 7) {
              //   item.payMethodStr = "通联支付";
              // }
              item.amountStr = item.amount ? '￥' + Number(item.amount).toFixed(2) : '￥0.00';
              item.cardNum = item.memberCard ? item.memberCard : '--';
            })
          }
          opt.cb(res.data)
        })

      },
    }
  }
</script>

<style lang="scss" scoped>
  .c {
    margin-top: 10px;
    height: 100%;
  }
</style>