<template>
  <ul class="tab-ul">
    <li v-for="(item, index) in tabs" :key="index" :class="{'ative':currentIndex==index}" @click="toggleTab(index)">{{item.label}}</li>
  </ul>
</template>

<script>
export default {
  props: {
    "tabs": {
      type: Array,
      require: true,
      default: () => []
    },
    "tabIndex": {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      currentIndex: this.tabIndex
    }
  },

  methods: {
    toggleTab: function (index) {
      this.$emit('tabChange', index)
    }
  },

  watch: {
    tabIndex: function (newVal, oldVal) {
      console.error('监听到了tabIndex=', newVal)
      this.currentIndex = newVal
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  height: 42px;
  box-sizing: border-box;
  margin-bottom: 0px;
  width: 100%;
  border-bottom: 2px solid #e4e7ed;

  li {
    cursor: pointer;
    padding-left: 18px;
    padding-right: 18px;
    height: 42px;
    line-height: 42px;
    float: left;
    text-align: center;
    margin-right: 13px;
    font-size: 15px;
    background-color: #ffffff;
    color: #303133;
    box-sizing: border-box;
    border-bottom: 2px solid #e4e7ed;

    &.ative {
      color: #30bac1;
      border-bottom: 2px solid #30bac1;
    }
  }

  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
</style>
