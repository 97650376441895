<template>
  <div class="c">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
  </div>
</template>

<script>
/**
 * 会员充值记录组件
 */
export default {
  data() {
    return {
      opt: {
        search: [
          {
            key: "payTime",
            label: "充值时间",
            timeType: "yyyy-MM-dd HH:mm:ss",
            format: "yyyy-MM-dd",
            type: "daterange"
          }
        ],
        columns: [
          // {
          //   label: "会员卡",
          //   key: "cardCode"
          // },
          {
            label: "会员卡号",
            key: "cardNum"
          },
          {
            label: "充值金额",
            key: "amount"
          },
          {
            label: "充值时间",
            key: "payTime"
          },
          {
            label: "充值单号",
            key: "id"
          },
          {
            label: "充值状态",
            key: "stateStr"
          }
        ]
      }
    }
  },
  methods: {
   onGet(opt) {
       let searchForm = opt.searchForm
      let dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        bizUserId: this.$route.query.userId,
        startTime: opt.searchForm.payTime ? opt.searchForm.payTime[0] : null,
        endTime: opt.searchForm.payTime ? moment(opt.searchForm.payTime[1]).format("YYYY-MM-DD 23:59:59") : null,
        // ...opt.searchForm
      }
      this.post('/mall-service/recharge/v1/order/page',dto,{
        isUseResponse: true
      }).then(res =>{
        let data = {
          data: [],
          total: 0,
        }
        data.data = res.data.list;
        data.total = res.data.total;
        if (res.data.list) {
          res.data.list.forEach(item => {
              if(item.state == 1) {
                item.stateStr = '充值成功';
              }else if(item.state == 2){
                item.stateStr = '充值失败';
              }
              item.cardNum = item.memberNo ? item.memberNo : '--';
            })
          } 
          opt.cb(data)  
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.c {
  margin-top: 10px;
  height: 100%;
}
</style>