var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "tab-ul" },
    _vm._l(_vm.tabs, function(item, index) {
      return _c(
        "li",
        {
          key: index,
          class: { ative: _vm.currentIndex == index },
          on: {
            click: function($event) {
              _vm.toggleTab(index)
            }
          }
        },
        [_vm._v(_vm._s(item.label))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }